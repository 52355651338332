<template>
  <!--功能列表-->
  <div class="Help">
    <div id="book-canvas">

    </div>
    <div class="Help-box">
      <div class="Help-content">
        <div class="content-create">
          <ul class="cr-eation">
            <h3>
              <img src="../../assets/img/user/Iitem0selected/creation1139.png" alt="">
              {{ getString(Strings.Create_a_Mind_Map) }}
            </h3>
            <li style="width: 64%;">{{ getString(Strings.Create_Mind_Map_1) }}
            </li>
            <li>{{ getString(Strings.Create_Mind_Map_2) }}</li>
            <li>{{ getString(Strings.Create_Mind_Map_3) }}</li>
            <li>{{ getString(Strings.Create_Mind_Map_4) }}</li>
            <li>{{ getString(Strings.Create_Mind_Map_5) }}</li>
            <li>{{ getString(Strings.Create_Mind_Map_6) }}</li>
            <li>{{ getString(Strings.Create_Mind_Map_7) }}</li>
            <li>{{ getString(Strings.Create_Mind_Map_8) }}</li>
            <li>{{ getString(Strings.Create_Mind_Map_9) }}</li>
            <li>{{ getString(Strings.Create_Mind_Map_10) }}</li>
            <li>{{ getString(Strings.Create_Mind_Map_11) }}</li>
            <li>{{ getString(Strings.Create_Mind_Map_12) }}</li>
            <li>{{ getString(Strings.Create_Mind_Map_13) }}</li>
            <h3>
              <img src="../../assets/img/user/Iitem0selected/beautify1141.png" alt="">
              {{ getString(Strings.Beautify_The_Mind_Map) }}
            </h3>
            <li>{{ getString(Strings.Beautify_The_Mind_Map_1) }}</li>
            <li>{{ getString(Strings.Beautify_The_Mind_Map_2) }}</li>
            <li>{{ getString(Strings.Beautify_The_Mind_Map_3) }}</li>
            <li>{{ getString(Strings.Beautify_The_Mind_Map_4) }}</li>
            <li>{{ getString(Strings.Beautify_The_Mind_Map_5) }}</li>
            <li>{{ getString(Strings.Beautify_The_Mind_Map_6) }}</li>
            <li>{{ getString(Strings.Beautify_The_Mind_Map_7) }}</li>
            <li>{{ getString(Strings.Beautify_The_Mind_Map_8) }}</li>
            <li>{{ getString(Strings.Beautify_The_Mind_Map_9) }}</li>
            <li v-html="getString(Strings.Beautify_The_Mind_Map_10)">
            </li>
            <h3>
              <img src="../../assets/img/user/Iitem0selected/educe1142.png" alt="">
              {{ getString(Strings.Map_Output) }}
            </h3>
            <li>{{ getString(Strings.Map_Output_1) }}</li>
            <li>{{ getString(Strings.Map_Output_2) }}</li>
            <li>{{ getString(Strings.Map_Output_3) }}</li>
            <li>{{ getString(Strings.Map_Output_4) }}</li>
            
          </ul>

        </div>


      </div>
    </div>

  </div>
</template>

<script>
import unifildStyle from "../../assets/css/help/unifildStyle.less";
import Strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import BookData from "../../views/book/data/BookData";
import BookView from "../../views/book/ui/BookView";

export default {
  components: {},
  data() {
    return {
      Strings:Strings
    };
  },
  created() {},
  mounted() {
    // let context = document.getElementById('book-canvas');
    // let bookData = new BookData();
    // let bookView = new BookView(context, bookData);
  },
  methods: {
    getString(i){
     return getString(i)
    },
    getSearch(k) {
      // 获取地址栏内容
      var str = window.location.href.split("?")[1];
      // 解码成中文
      str = decodeURIComponent(str);
      var arr = str.split("&");
      var obj = {};
      // 获取键和值
      arr.forEach(function (v, i) {
        var key = v.split("=")[0];
        var value = v.split("=")[1];
        obj[key] = value;
      });
      return obj[k];
    },
  },
  watch: {},
  computed: {},
};
</script>

<style scoped>
ul li {
  list-style: none;
}
.content-create h3 {
  color: #FD492B;
  margin-bottom: 10px;
  margin-top: 20px;
  font-family: Noto Sans SC;
  font-size: 18px;
  text-indent: -10px;
}
.content-create ul li{
  color: #666;
  font-family: Noto Sans SC;
  font-size: 14px;
  line-height: 30px;
  text-indent: -10px;
}
.content-create ul{
    margin-bottom: 2em;
    float: left;
    margin-right: 2em;
    margin-left: 20px;
    padding-left: 40px;
}
.cr-eation{
  width: 50%;
  
}

.cr-eation li {
  margin-left: 20px;
}
.cr-educe{
  height: 250px;
  width: 45%;
}
.cr-beautify{
  float: unset;
  width: 50%;
}

</style>